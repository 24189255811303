import React from "react";
import LibararyComp from "../components/library/LibararyComp";

const Library = () => {
  return (
    <div className="mb-32">
      {" "}
      <LibararyComp />
    </div>
  );
};

export default Library;
