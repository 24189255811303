import React, { useState } from "react";
import { BookCard } from "../../cards";
import { journals } from "./books";

const LibararyComp = () => {
  const books = journals; // Array of all books
  const booksPerPage = 10; // Number of books per page
  const totalPages = Math.ceil(books.length / booksPerPage); // Total number of pages
  const [currentPage, setCurrentPage] = useState(1); // Track the active page

  // Calculate the books to display for the current page
  const startIndex = (currentPage - 1) * booksPerPage;
  const currentBooks = books.slice(startIndex, startIndex + booksPerPage);

  return (
    <>
      <h1 className="mt-5 md:ml-20 text-xl font-bold">Journals</h1>
      <div className="bg-black ml-20 h-[2px] w-11/12"></div>

      {/* Display books for the current page */}
      <div className="flex flex-wrap bg-slate-100 justify-center mt-10 mb-10 gap-5">
        {currentBooks.map((book, index) => (
          <div key={index}>
            <BookCard
              title={book.title}
              coverImg={book.coverImg}
              authors={book.authors}
              volume={book.volume}
              doi={book.doi}
              category={book.CateGory}
              route={startIndex + index} // Adjust route index
            />
          </div>
        ))}
      </div>

      {/* Pagination Buttons */}
      <div className="flex w-full gap-5 justify-end items-end absolute right-20">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => setCurrentPage(index + 1)} // Set the active page
            className={`rounded-full p-3 pl-5 pr-5  ${
              currentPage === index + 1
                ? "bg-yellow-400 text-black font-bold"
                : "bg-blue-900 text-white"
            }`}>
            {index + 1}
          </button>
        ))}
      </div>
    </>
  );
};

export default LibararyComp;
