import React from "react";
import { CommerceHeading } from "../components";
import CommerceSectionsTemplate from "../components/commerce/CommerceSectionsTemplate";
import {
  eiThandarAung,
  mayThuKhaing,
  shoonLaeWai,
  sieSarOo,
  thandarAung,
  yinKyawtMon,
  yuMonAung,
  yeWintAung,
  kyiKyiThant,
  commerceActiBOne,
  commerceActiBTwo,
  commerceActiBThree,
  commerceActiAOne,
  commerceActiCOne,
  commerceActiDOne,
  unknownMan,
  unknownWoman,
} from "../assets";
const curriculums = [
  {
    title: "Bachelor of Commerce",
    degrees: [
      "Myanmar",
      "Business English",
      "Business Mathematics",
      "Introductory Microeconomics",
      "Fundamentals of Statistics",
      "Business Organization",
      "Aspects of Myanmar",
      "New Economic Geography",
      "Introductory Macroeconomics",
      "Business Law",
      "Business Accounting",
      "General Management",
      "Cost and Management Accounting",
      "Advanced Accounting",
      "Management Science",
      "Business Communication",
      "Production and Operation Management",
      "Human Resource Management",
      "Business Research Methods",
      "Business Planning",
      "Financial Reporting Standards & Public Sector Accounting",
      "Financial Institution Management",
      "Risk Management",
      "Consumer Behavior",
      "Organizational Behaviour",
      "International Accounting",
      "Marketing Management",
      "Innovation and Entrepreneurship",
      "Hospitality Management",
    ],
  },
  {
    title: "Bachelor of Accounting",
    degrees: [
      "Myanmar",
      "Business English",
      "Business Mathematics",
      "Introductory Microeconomics",
      "Fundamentals of Statistics",
      "Business Organization",
      "Aspects of Myanmar",
      "New Economic Geography",
      "Introductory Macroeconomics",
      "Business Law",
      "Business Accounting",
      "General Management",
      "Cost and Management Accounting",
      "Financial Accounting",
      "Applied Statistics (Elective Courses)",
      "Marketing Management",
      "Business Communication",
      "Financial Law",
      "Management Science",
      "Advanced Accounting",
      "Production and Operation Management",
      "Human Resource Management",
      "Business Research Methods",
      "Business Planning",
      "Financial Reporting Standards & Public Sector Accounting",
      "Auditing",
      "Accounting Information System",
      "International Accounting",
    ],
  },
  {
    title: "B Com Qualifying/Honours",
    degrees: [
      "Organizational Behaviour",
      "Money, Banking and Finance",
      "Service Marketing Management",
      "Strategic Management",
      "Customer Communication",
      "Managerial Leadership",
      "Advanced Management and Change",
      "Portfolio Management",
      "International Business Management",
      "Customer Relationship Management",
    ],

    titleTwo: "B Act Qualifying/Honours",
    degreesTwo: [
      "Advanced Financial Reporting Standards",
      "Money, Banking & Finance",
      "Strategic Management Accounting",
      "Capital Markets & Finance",
      "Customer Communication",
      "Applied Marketing Research",
      "Portfolio Management",
      "Corporate Economics",
      "Economic Financial Reporting",
      "Business Ethics",
      "Entrepreneurship & Small Business Management",
    ],
  },

  {
    title: "Ph.D (Commerce) Preliminary Course",
    degrees: [
      "Consumer Behavior",
      "Service Marketing Management",
      "Management Science",
      "Research Methodology",
      "Globalizing Human Resource Management",
      "Organizational Behaviour",
      "Financial Reporting & Strategic Management",
      "Business Codes of Ethics",
    ],
    titleTwo: "Master of Commerce & Accounting",
    degreesTwo: [
      "Strategic Management",
      "Advanced Marketing Management",
      "Human Resource Management",
      "Advanced Financial Management",
      "Advanced Production & Operations Management",
      "Consumer Behavior",
      "Bank Management",
      "Managerial Mathematics/ Statistical Analysis and Research Methods",
      "Business Ethics",
      "Auditing and Assurance Services",
      "International Finance (Optional Courses)",
      "International Business Management (Optional Courses)",
    ],
  },
];

const headInfo = {
  name: "Dr. Kyi Kyi Thant ",
  profileImage: kyiKyiThant,
  department: "Department of Commerce",
  position: "Head Of Department",
  passage:
    "Monywa University of Economics. She takes the responsibility of managing the department since June 2024. She got Bachelor of Commerce from Yangon University of Economics in 2003 and attained Master of Commerce from Yangon University of Economics in 2007. She gained human resource development scholarship from Japan International Cooperation Agency (JICA). She obtained Master of Arts from Graduate School of International Development and Cooperation, Hiroshima University, Japan. She got Doctoral Degree specializing in Commerce from Meiktila University of Economics in 2016. She started working as a tutor in Meiktila University of Economics since 2005, and she was promoted to assistant lecturer in 2013, to lecturer in 2016, to associate professor in 2019, and to professor in 2021. She had been working for 18 years and 9 months in Meiktila University of Economics. She is currently working at Monywa University of Economics as a Professor, Head of Department of Commerce. She has the total working experience of 19 years and 2 months. She is the Program Director Master of Marketing Management Programme and Diploma in Business Studies offered by Department of Commerce, Monywa University of Economics.",
};
const members = [
  [
    {
      name: "Dr. Kyi Kyi Thant",
      position: "Professor (Head)",
      profile: kyiKyiThant,
    },
  ],
  [
    {
      name: "Dr. Ye Wint Aung",
      position: "Lecturer",
      profile: yeWintAung,
    },
    {
      name: "U Pyae Phyo Aung",
      position: "Lecturer",
      profile: unknownMan,
    },
    { name: "Daw Thandar Aung", position: "Lecturer", profile: thandarAung },
  ],
  [
    {
      name: "Daw Thazin Oo",
      position: "Assistant Lecturer",
      profile: unknownWoman,
    },
    {
      name: "Daw Sie Sar Oo",
      position: "Assistant Lecturer",
      profile: sieSarOo,
    },
    {
      name: "Daw Chein Yi Win",
      position: "Assistant Lecturer",
      profile: unknownWoman,
    },
  ],
  [
    {
      name: "Daw Yu Mon Aung",
      position: "Tutor",
      profile: yuMonAung,
    },
    {
      name: "Daw Shoon Lae Wai",
      position: "Tutor",
      profile: shoonLaeWai,
    },
    {
      name: "Daw Mya Thu Khaing",
      position: "Tutor",
      degree: "BCom(Hons), MCom, MBA(IUJ), PhD(Commerce)",
      profile: mayThuKhaing,
    },
    {
      name: "Daw Ei Thandar Aung",
      position: "Tutor",
      profile: eiThandarAung,
    },
    {
      name: "Daw Yin Kyawt Mon",
      position: "Tutor",
      profile: yinKyawtMon,
    },
  ],
];
const degrees = [
  "Doctor of Philosophy (Commerce)",
  "Master of Commerce",
  "Master of Accounting",
  "Bachelor of Commerce",
  "Bachelor of Commerce, Honours",
  "Bachelor of Accounting",
  "Bachelor of Accounting, Honours",
];
const programs = [
  "Master of Marketing Management (MMM)",
  "Master of Banking and Finance (MBF)",
  "Postgraduate Diploma in Business Studies",
];
const facultyResearch = [
  {
    name: "Dr. Kyi Kyi Thant  (Professor/ Head ) ",
    research: [
      "Determinants of job satisfaction among employees in the Meiktila Industrial Zone, Myanmar. Journal of the Myanmar Academy of Arts and Science, 10(11), 215-230. (2012)",
      "Employee perception towards human resource management practices of hotels in Bagan. Meiktila University of Economics Research Journal, 7(1), 57-66. (2016)",
      "Organizational learning of private training schools in Mandalay. Meiktila University of Economics Research Journal, 8(1), 61-69. (2017)",
      "Information system development of hotels in Bagan. Myanmar Universities’ Research Conference 2019, Conference Proceedings, 1(2), 150-154. (2019)",
      "Innovation of weaving firms in Wundwin Township. Meiktila University of Economics Research Journal, 9(1), 169-177. (2019)",
      "Information system adoption of private hospitals in Mandalay Region. University Journal of Research and Innovation (University of Computer Studies, Pakokku), 1(1), 121-126. (2019)",
      "Information system development of hotels in Naypyitaw, Myanmar. Journal of the Myanmar Academy of Arts and Science, Special Issue, 157-164. (2019)",
      "Driving forces and outcomes of information system development in Hotels, Bagan. Meiktila University of Economics Research Journal, 10(1), 95-109. (2020)",
      "Competitive advantage through innovation of hotels in Nay Pyi Taw. The Second Myanmar Universities’ Research Conference (2020) Conference Proceedings, 2(2), 72-77. (2020)",
      "Entrepreneurial Competencies of garment manufacturers in Wundwin Township. Journal of the Myanmar Academy of Arts and Science, 18(8), 29-37. (2020)",
      "Factors influencing the adoption of mobile payment: An empirical analysis. Meiktila University of Economics, 10(2), 97-109. (2020)",
      "Employee commitment to private schools in Meiktila Township, Universities Research Journal, 12(8), 283-296. (2020)",
      "Resources and competitive advantage of weaving firms in Wundwin Township. The Myanmar Journal, 8(1), 214-223. (2021)",
      "Information system development of hotels in Mandalay. Journal of the Myanmar Academy of Arts and Science, 19(8), 25-39. (2021)",
      "Workplace stress and burnout among employees in the banking industry, Myanmar. International Journal of Business, Economics and Law, 25(2), 24-30. (2021)",
      "Behavioral intention to use e-learning: Application of technology acceptance model. Journal of Education and Social Sciences, 22(1), 20-26. (2022)",
      "Challenge and hindrance stressors of employees in the banking industry, Myanmar. Meiktila University of Economics Research Journal, 12(1), 49-61. (2023)",
      "Internal marketing and employee job satisfaction in CB Bank in Pyinmana Township. Meiktila University of Economics Research Journal, 12(1), 126-137. (2023)",
    ],
  },
  {
    name: "Dr. Ye Wint Aung  ( Lecture )",
    research: [
      "Consumers Attitude towards Single-Brand Apparel Retailers in Mandalay Ph.D Commerce 2020, July.",
      "Consumers Attitude towards Single-Brand Apparel Retailers in Mandalay MUE Research Journal 2020, March Vol.11, No. 2 21-44.",
      "Effect of Participants Characteristics on Consumers Attitude towards Single-Brand Apparel Retailers in Mandalay MUE Research Journal 2023, March Vol.13.",
      "Marketing Communication and Repurchase Intention towards KFC Myanmar MUE Research Journal 2023, March Vol.13.",
      "Consumers Behaviors towards Single-Brand Apparel Retailers in Mandalay Myanmar MUE Research Journal 2024, March Vol.14.",
    ],
  },
  {
    name: "Daw Sie Sar Oo  ( Assistant Lecturer )",
    research: [
      "Psychological Empowerment and Employee Performance at KMA Hotel Group in Bagan Research Paper Reading Seminar in Commemoration of the 23rd Anniversary of Monywa, Oct 1-2/2019 Pg.255-259.",
      "Consumers Attitude towards Single-Brand Apparel Retailers in Mandalay MUE Research Journal 2020, March Vol.11, No. 2 21-44.",
      "Effect of Participants Characteristics on Consumers Attitude towards Single-Brand Apparel Retailers in Mandalay MUE Research Journal 2023, March Vol.13.",
    ],
  },
  {
    name: "Daw Shoon Lae Wai  ( Tutor )",
    research: [
      "The Impact of Employee Relationship Management on Employee Satisfaction and Retention at Cane Firm in Sagaing MUE Research Journal 2022.",
      "Marketing Communication and Repurchase Intention towards KFC Myanmar MUE Research Journal 2023, March Vol.12 111-125.",
      "Relationship Marketing Practices and Customer Loyalty at AYA Bank in Monywa MUE Research Journal 2024, March Vol.14 112-129.",
    ],
  },
  {
    name: "Daw Yu Mon Aung  ( Tutor )",
    research: [
      "Relationship Marketing Practices and Customer Loyalty at AYA Bank in Monywa MUE Research Journal 2024, March Vol.14 112-129.",
    ],
  },
  {
    name: "Daw May Thu Khaing  ( Tutor )",
    research: [
      "Internal Marketing Practices, Job Satisfaction and Organizational Commitment at Global Treasure Bank MUE Research Journal 2023, March Vol.12 95-110.",
      "Transfer of Training and University Teachers Performance in Monywa MUE Research Journal 2023, March Vol.12 126-142.",
      "Determinants of Students Perceived Learning and Satisfaction in Online Learning MUE Research Journal 2023, March Vol.14 198-211.",
    ],
  },
  {
    name: "Daw Ei Thandar Aung  ( Tutor )",
    research: [
      "Transfer of Training and University Teachers Performance in Monywa MUE Research Journal 2023, March Vol.12 126-142.",
      "Internal Marketing Practices, Job Satisfaction and Organizational Commitment at Global Treasure Bank MUE Research Journal 2023, March Vol.12 95-110.",
      "Organizational Change and Employee Performance of Internal Revenue Department MUE Research Journal 2023, March Vol.12 158-169.",
      "Retail Service Quality and Customer Loyalty of Ocean Supermarket MUE Research Journal 2023, March Vol.14 246-259.",
    ],
  },
  {
    name: "Daw Yin Kyawt Mon  ( Tutor )",
    research: [
      "The Impact of Employee Relationship Management on Employee Satisfaction and Retention at Cane Firm in Sagaing MUE Research Journal 2022.",
      "Marketing Communication and Repurchase Intention towards KFC Myanmar MUE Research Journal 2023, March Vol.12 111-125.",
    ],
  },
];

const activities = [
  { title: "MMM & MBF  ဆရာကန်တော့ပွဲ", image: commerceActiBOne },
  { title: "MMM & MBF  ဆရာကန်တော့ပွဲ", image: commerceActiBTwo },
  { title: "MMM & MBF  ဆရာကန်တော့ပွဲ", image: commerceActiBThree },
  { title: "Department Staff Development", image: commerceActiAOne },
  { title: "ဌာနဉပုသ်ပွဲ", image: commerceActiCOne },
  { title: "ဌာနတွင်းသန့်ရှင်းရေး", image: commerceActiDOne },
];

const CommerceDepartment = () => {
  return (
    <>
      <div className="mobile-margin-x-zero md:ml-32 md:mr-32">
        <CommerceHeading />
        <CommerceSectionsTemplate
          curriculumsTypes={curriculums}
          headMaster={headInfo}
          groupMembers={members}
          researches={facultyResearch}
          news={activities}
          offeredDegrees={degrees}
          hrdProgrammes={programs}
        />
      </div>
    </>
  );
};

export default CommerceDepartment;
