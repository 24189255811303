import { IoChevronBack } from "react-icons/io5";
import { Link, useParams } from "react-router-dom";
import { journals } from "../components/library/books";
import { bookCoverImg } from "../assets";

export default function ChosenBook() {
  const { index } = useParams();
  console.log(index);
  const books = journals;
  const book = books[index];

  return (
    <div className="flex flex-col justify-start md:pl-32 md:pr-32 items-start mt-10">
      <Link to="/library" className="flex text-xl font-bold gap-2">
        <IoChevronBack size={25} />
        <p>Go Back</p>
      </Link>

      <div className="w-full mb-10 flex bg-white flex-col md:flex-row justify-center items-start md:items-start gap-5 p-5">
        <img
          src={book.coverImg ? book.coverImg : bookCoverImg}
          alt={book.title}
          className="h-56"
        />
        <div className="flex  flex-col items-start justify-start gap-2">
          <h3 className="text-sm font-bold">{book.title}</h3>
          {[
            { label: "Authors", value: book.authors, push: "ml-[46px]" },
            { label: "Volume", value: book.volume, push: "ml-[50px]" },
            { label: "DOI", value: book.doi, push: "ml-[75px]" },
            { label: "Category", value: book.category, push: "ml-10" },
            { label: "Published Year", value: book.publishedYear },
            { label: "Keywords", value: book.keywords, push: "ml-8" },
          ].map((item, index) => (
            <div key={index} className="flex flex-row gap-3">
              {item.value && (
                <>
                  <p className="text-sm font-semibold">{item.label}</p>
                  <p className={`${item.push} text-sm font-semibold`}>:</p>
                  <h1 className="text-sm">{item.value}</h1>
                </>
              )}
            </div>
          ))}
          <a href={book.pdfRoute} target="_blank" rel="noopener noreferrer">
            <button
              className="p-1 pl-5 pr-5 rounded-md font-bold text-base text-white"
              style={{ backgroundColor: "rgb(41, 74, 112)" }}>
              Read Now
            </button>
          </a>

          {book.description && (
            <div>
              <h3>Description</h3>
              {book.description.map((fact, index) => (
                <p key={index} className="mb-3 text-sm">
                  {fact}
                </p>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
