import { Flex, Image, Text } from "../../ui";
import {
  khineMarlar,
  nilarMyint,
  ourMotto,
  ourVision,
  ringLogo,
  soeThu,
  wahWahThan,
} from "../../assets";
import { ProfileCard } from "../../cards";
import Mainbuilding from "../../assets/hero/MainBuilding.jpg";

function AboutHeader() {
  return (
    <>
      <Flex direction="col" justify="start" align="center" className="gap-5">
        <Image src={ringLogo} width="300px" height="300px" alt="Site Logo" />
        <Text
          color="black"
          size="25px"
          weight="500"
          content=" MONYWA UNIVERSITY OF ECONOMICS"
        />
        <div
          className="h-1 w-11 hover:w-32 hover:duration-1000 mb-5 mt-2"
          style={{ backgroundColor: "rgba(255, 215, 0, 1)" }}></div>

        <Text
          color="black"
          size="20px"
          weight="400"
          className="text-justify mb-3"
          content="Monywa Economics College was established in Monywa Township, Sagaing Region in 1993 according to the permission of the State Peace and Development Council, Myanmar for the purpose of developing the economy in Upper Myanmar. According to the notification by the Ministry of Education, “Monywa Economics College” was upgraded to “Monywa Institute of Economics” in 1998."
        />
        <Text
          color="black"
          size="20px"
          weight="400"
          className="text-justify"
          content="From the University’s inception until the 2022-2023 academic year, a total of 13,591 students have been awarded the degrees, including 102,665 Bachelor Degrees, 660 Honours Degrees, 735 Diplomas, 1,899 Master Degrees, and 32 Doctoral Degrees."
        />
        <Image
          src={nilarMyint}
          alt="Prof.Dr. Nilar Myint Htoo"
          width="155px"
          height="205px"
          className="m-7 mb-3"
        />

        <Text
          color="black"
          size="20px"
          weight="400"
          className="text-justify "
          content="Prof. Dr. Nilar Myint Htoo"
        />
        <Text
          color="black"
          size="16px"
          weight="400"
          className="text-justify"
          content="Rector"
        />
        <Flex direction="row" justify="evenly" className="mb-5 mobile-column">
          <ProfileCard
            name="Prof. Dr. Daw Soe Thu"
            position="Pro-Rector (Academics)"
            image={soeThu}
          />
          <ProfileCard
            name="Prof. Dr. Khine Marlar Hlaing"
            position="Pro-Rector (Adminstration & Finance)"
            image={khineMarlar}
          />
          <ProfileCard
            name="Prof. Dr. Wah Wah Than Oo"
            position="Pro-Rector(IR & Research)"
            image={wahWahThan}
          />
        </Flex>
        <Image src={ourMotto} width="61px" height="61px" alt="Our Motto" />
        <Text size="30px" color="rgba(0, 51, 102, 1)" content="OUR MOTTO" />
        <div
          className="h-1 -translate-y-2 w-11 hover:w-32 hover:duration-1000"
          style={{ backgroundColor: "rgba(255, 215, 0, 1)" }}></div>

        <Text
          size="18px"
          color="rgba(51, 51, 51, 1)"
          content="• Learn, Insight, Achieve"
          className="mb-5 -translate-y-3 text-justify"
        />
        <Flex
          direction="row"
          justify="evenly"
          align="start"
          className="gap-5 mobile-column">
          <Flex direction="col">
            <Image
              src={ourMotto}
              width="61px"
              height="61px"
              alt="Our Core Values"
            />
            <Text
              size="30px"
              color="rgba(0, 51, 102, 1)"
              content="OUR CORE VALUES"
            />
            <div
              className="h-1 m-2 w-11 hover:w-32 hover:duration-1000"
              style={{ backgroundColor: "rgba(255, 215, 0, 1)" }}></div>
            <ul className="list-disc pl-10">
              <li className="text-justify">• Wisdom and Mastery</li>
              <li className="text-justify">• Ethical Professionalism</li>
              <li className="text-justify">• Creativity and Innovation</li>
              <li className="text-justify">• Discipline and Mutual Respect</li>
              <li className="text-justify">• Global Collaboration</li>
            </ul>
          </Flex>
          <Image
            src={Mainbuilding}
            width="338px"
            height="213px"
            alt="Main Building"
            className="mt-10 shadow-yellow-400 shadow-md rounded-xl mobile-hidden"
          />

          <Flex direction="col">
            <Image src={ourMotto} width="61px" height="61px" alt="Our Motto" />
            <Text
              size="30px"
              color="rgba(0, 51, 102, 1)"
              content="OUR MISSION"
            />
            <div
              className="h-1 m-2 w-11  hover:w-32 hover:duration-1000"
              style={{ backgroundColor: "rgba(255, 215, 0, 1)" }}></div>
            <ul className="list-disc pl-10">
              <li className="text-justify">
                • To provide a learning environment in which faculty and staff
                can discover continuously, examine critically, and transmit
                sustainably the knowledge, wisdom and values
              </li>
              <li className="text-justify">
                • To empower the student engagement in local and global
                communities in accordance with the changing environment
              </li>

              <li className="text-justify">
                • To encourage the students to have patriotism, nationalism and
                civic responsibilities
              </li>

              <li className="text-justify">
                • To cultivate habits of lifelong learning and student-centered
                teaching and learning{" "}
              </li>
              <li className="text-justify">
                • To emphasize creative and cutting-edge conducting
                state-of-the-art research
              </li>
            </ul>
          </Flex>
        </Flex>
        <Flex className="about-vision" direction="col">
          <Image src={ourVision} width="61px" height="61px" alt="Our Vision" />
          <div
            className="h-1 m-2  w-11  hover:w-32 hover:duration-1000"
            style={{ backgroundColor: "rgba(255, 215, 0, 1)" }}></div>
          <Text size="30px" color="rgba(0, 51, 102, 1)" content="OUR VISION" />
          <Text
            size="18px"
            color="rgba(51, 51, 51, 1)"
            content="•To become a world-class university nurturing responsible intellectuals and professionals in Economics."
            className="mb-8 w-64 text-justify"
          />
        </Flex>
      </Flex>
    </>
  );
}

export default AboutHeader;
