import React, { useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";
import { Text, Flex } from "../../ui";

function AboutBottom() {
  const scrollContainerRef = useRef(null); // Reference for scrollable container
  const controls = useAnimation(); // Framer Motion animation controls

  const rectors = [
    { name: "Dr. Kan Zaw", date: "1997-2000", isActingRector: false },
    { name: "U Yu Pa", date: "2000-2001", isActingRector: false },
    { name: "U Than Aung Yin", date: "2001-2004", isActingRector: false },
    { name: "U Aung Myint", date: "2004-2014", isActingRector: true },
    { name: "Dr. Win Htun", date: "2014-2016", isActingRector: false },
    { name: "Dr. Khin Khin Htwe", date: "2016-2017", isActingRector: false },
    { name: "Dr. Soe Win", date: "2017-2021", isActingRector: false },
    { name: "Dr. Thida Kyu", date: "2021-2024", isActingRector: false },
    {
      name: "Dr. Nilar Myint Htoo",
      date: "2024-Current",
      isActingRector: false,
    },
  ];

  useEffect(() => {
    const scroll = () => {
      if (scrollContainerRef.current) {
        const maxScrollLeft =
          scrollContainerRef.current.clientWidth -
          scrollContainerRef.current.scrollWidth;

        controls.start({
          x: [0, maxScrollLeft, 0], // Scroll to the end and back to the start
          transition: {
            duration: 20,
            ease: "linear",
            repeat: Infinity, // Repeat indefinitely
          },
        });
      }
    };

    scroll(); // Start the scroll animation
  }, [controls]);

  return (
    <>
      {/* Scrollable container */}
      <motion.div
        ref={scrollContainerRef}
        animate={controls}
        style={{
          overflowX: "visible", // Enable horizontal scrolling
          whiteSpace: "nowrap", // Prevent flex children from wrapping
          paddingBottom: "10px", // Space at the bottom to prevent cutoff
          width: "100%", // Set a fixed width for the container
          maxWidth: "100%", // Ensure the container doesn't exceed its parent width
          scrollbarWidth: "auto",
        }}
        className="flex flex-row">
        {rectors.map((rector, index) => (
          <Flex
            direction="row"
            justify="center"
            align="start"
            key={index}
            style={{ display: "inline-flex", marginRight: "10px" }} // Ensure inline-flex and spacing between items
          >
            <Flex direction="col" align="center" justify="center">
              <Text
                color="black"
                backgroundColor="#FFD700"
                size="15px"
                weight="600"
                className="whitespace-nowrap overflow-hidden text-ellipsis pl-3 pr-3 p-1 rounded-2xl"
                content={rector.name}
              />
              <Text
                color="black"
                size="12px"
                weight="400"
                content={rector.isActingRector ? "Acting Rector" : "Rector"}
              />
              <Text
                color="rgba(41, 74, 112, 1)"
                size="15px"
                weight="600"
                content={rector.date}
              />
            </Flex>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="7"
              viewBox="0 0 69 7"
              className="translate-y-3"
              fill="none">
              <path
                d="M68.5 3.43847L63.5 0.55172L63.5 6.32522L68.5 3.43847ZM0.5 3.93848L1.5 3.93848L1.5 2.93848L0.5 2.93848L0.5 3.93848ZM3.5 3.93848L5.5 3.93848L5.5 2.93848L3.5 2.93848L3.5 3.93848ZM7.5 3.93848L9.5 3.93848L9.5 2.93848L7.5 2.93848L7.5 3.93848ZM11.5 3.93848L13.5 3.93848L13.5 2.93848L11.5 2.93848L11.5 3.93848ZM15.5 3.93848L17.5 3.93848L17.5 2.93848L15.5 2.93848L15.5 3.93848ZM19.5 3.93848L21.5 3.93847L21.5 2.93847L19.5 2.93848L19.5 3.93848ZM23.5 3.93847L25.5 3.93847L25.5 2.93847L23.5 2.93847L23.5 3.93847ZM27.5 3.93847L29.5 3.93847L29.5 2.93847L27.5 2.93847L27.5 3.93847ZM31.5 3.93847L33.5 3.93847L33.5 2.93847L31.5 2.93847L31.5 3.93847ZM35.5 3.93847L37.5 3.93847L37.5 2.93847L35.5 2.93847L35.5 3.93847ZM39.5 3.93847L41.5 3.93847L41.5 2.93847L39.5 2.93847L39.5 3.93847ZM43.5 3.93847L45.5 3.93847L45.5 2.93847L43.5 2.93847L43.5 3.93847ZM47.5 3.93847L49.5 3.93847L49.5 2.93847L47.5 2.93847L47.5 3.93847ZM51.5 3.93847L53.5 3.93847L53.5 2.93847L51.5 2.93847L51.5 3.93847ZM55.5 3.93847L57.5 3.93847L57.5 2.93847L55.5 2.93847L55.5 3.93847ZM59.5 3.93847L61.5 3.93847L61.5 2.93847L59.5 2.93847L59.5 3.93847ZM63.5 3.93847L65.5 3.93847L65.5 2.93847L63.5 2.93847L63.5 3.93847Z"
                fill="#153055"
              />
            </svg>
          </Flex>
        ))}
      </motion.div>
    </>
  );
}

export default AboutBottom;
