import React from "react";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import { bookCoverImg } from "../assets";

const BookCard = ({
  title,
  coverImg,
  authors,
  volume,
  doi,
  category,
  route,
}) => {
  return (
    <>
      <div className="shadow-md md:min-w-[45vw] w-[90vw] md:max-w-[45vw] min-h-[280px] max-h-[281px] flex bg-white  flex-row justify-start gap-5 p-5">
        <img
          src={coverImg ? coverImg : bookCoverImg}
          alt={title}
          className="h-24 w-auto sm:h-48"
        />
        <div className="flex flex-grow flex-col items-start justify-start gap:1 lg:gap-2">
          <div className="flex flex-row justify-start items-start gap-3">
            <p className="text-xs sm:text-sm font-semibold">Title</p>
            <p className="text-xs sm:text-sm font-semibold ml-8">:</p>
            <h1 className="text-sm xl:text-base  font-bold">{title}</h1>
          </div>{" "}
          <div className="flex flex-row justify-start whitespace-normal items-start gap-3">
            <p className="text-xs sm:text-sm font-semibold">Authors</p>
            <p className="text-xs sm:text-sm font-semibold ml-2">:</p>
            <h1 className="text-sm">{authors}</h1>
          </div>
          <div className="flex flex-row justify-start items-start gap-3">
            <p className="text-xs sm:text-sm font-semibold">Volume</p>
            <p className="text-xs sm:text-sm font-semibold ml-3">:</p>
            <h1 className="text-sm">{volume}</h1>
          </div>
          {doi && (
            <div className="flex flex-row justify-start items-start gap-3">
              <p className="text-xs sm:text-sm font-semibold">DOI</p>
              <p className="text-xs sm:text-sm font-semibold ml-9">:</p>
              <h1 className="text-sm whitespace-normal">{doi}</h1>
            </div>
          )}
          <div className="flex flex-row justify-start items-start gap-3">
            <p className="text-xs sm:text-sm font-semibold">Category</p>
            <p className="text-xs sm:text-sm font-semibold">:</p>
            <h1 className="text-sm">{category}</h1>
          </div>{" "}
          <Link
            to={`/library/jornals/${route}`}
            className="flex mt-auto font-bold items-center text-base text-blue-500 mb-0 flex-row justify-start  gap-3 mr-0 ml-auto">
            <p>View Details</p>
            <BsArrowRight size={30} />
          </Link>
        </div>
      </div>
    </>
  );
};

export default BookCard;
