import React, { useEffect, useRef } from "react";
import { Text } from "../ui";

import { motion, useAnimation } from "framer-motion";

const ActivitiesPostCard = ({ title, description, images }) => {
  const scrollContainerRef = useRef(null);
  const controls = useAnimation();

  useEffect(() => {
    const scroll = () => {
      if (scrollContainerRef.current) {
        const maxScrollLeft =
          scrollContainerRef.current.clientWidth -
          scrollContainerRef.current.scrollWidth;

        controls.start({
          x: [0, maxScrollLeft, 0], // Scroll to the end and back to the start
          transition: {
            duration: 20,
            ease: "linear",
            repeat: Infinity, // Repeat indefinitely
          },
        });
      }
    };

    scroll(); // Start the scroll animation
  }, [controls]);

  return (
    <div className="pb-9 mb-5 flex justify-start overflow-x-hidden whitespace-normal flex-wrap items-start max-w-[1170px] w-full flex-col border-b-2 border-dotted">
      <Text
        content={title}
        color="#333"
        weight="400"
        size="28px"
        className="text-left max-w-[100vw] text-wrap pr-5 h-auto"
      />
      <Text
        content={description}
        color="rgba(51, 51, 51, 0.8)"
        weight="400"
        size="20px"
        className="mt-5 text-left max-w-[100vw] text-wrap r-2 mb-5"
      />
      <motion.div
        ref={scrollContainerRef}
        animate={controls}
        style={{
          overflowX: "visible", // Enable horizontal scrolling
          whiteSpace: "nowrap", // Prevent flex children from wrapping
          paddingBottom: "10px", // Space at the bottom to prevent cutoff
          width: "100%", // Set a fixed width for the container
          maxWidth: "100%", // Ensure the container doesn't exceed its parent width
          scrollbarWidth: "auto",
        }}
        className="gap-5 overflow-x-hidden whitespace-nowrap flex flex-row">
        {images.map((image, index) => (
          <div
            key={index}
            style={{
              backgroundImage: `url(${image})`,
              backgroundSize: "cover",
              minWidth: "370px",
              minHeight: "355px",
              backgroundPosition: "center",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}>
            <Text
              content={`0${index + 1}`}
              backgroundColor="rgba(255, 215, 0, 0.5)"
              color="rgba(255, 255, 255, 1)"
              className="pr-5 pl-5 pt-1 pb-1"
            />
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default ActivitiesPostCard;
