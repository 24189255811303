import React, { useEffect, useRef } from "react";
import {
  ffimg,
  fsimg,
  ftimg,
  sfimg,
  ssimg,
  stimg,
  tfimg,
  tsimg,
  ttimg,
  fourthfimg,
  fourthsimg,
  fourthtimg,
  f4img,
  s4img,
  t4img,
  fifthfimg,
  fifthsimg,
  fifthtimg,
  environmentalCleaning,
  environmentalCleaning1,
  environmentalCleaning2,
  environmentalCleaning3,
  menChinLone,
  menChinLone1,
  trWorship,
  trWorship1,
} from "../../assets";
import volleyBall from "../../assets/2024Activities/volleyball.jpg";
import { Flex, Text } from "../../ui";
import { ActivitiesPostCard } from "../../cards";
import { useLocation } from "react-router-dom";

const posts = [
  {
    title: "စားသုံးသူကာကွယ်ရေးအသိပညာပေးဟောပြောပွဲကျင်းပ",
    description:
      "မုံရွာစီးပွားရေးတက္ကသိုလ်တွင် စားသုံးသူကာကွယ်ရေး အသိပညာပေးဟောပြောပွဲကို ၂၆-၈-၂၀၂၄ ရက်နေ့တွင် ကျင်းပပြုလုပ်ခဲ့ပါသည်။",
    images: [ffimg, fsimg, ftimg, f4img],
    redirect: "post-1",
  },
  {
    title: "မျောက်ကျောက်ရောဂါအကြောင်းအသိပညာပေးဟောပြောပွဲကျင်းပ",
    description:
      "	မုံရွာစီးပွားရေးတက္ကသိုလ်တွင် မျောက်ကျောက်ရောဂါအကြောင်း အသိပညာပေးဟောပြောပွဲကို ၃-၉-၂၀၂၄ ရက်နေ့တွင် ကျင်းပပြုလုပ်ခဲ့ပါသည်။",
    images: [sfimg, ssimg, stimg, s4img],
    redirect: "post-2",
  },
  {
    title: "ဥပဒေဆိုင်ရာအသိပညာပေးဟောပြောပွဲကျင်းပ",
    description:
      "	မုံရွာစီးပွားရေးတက္ကသိုလ်တွင် ဥပဒေဆိုင်ရာအသိပညာပေးဟောပြောပွဲကို ၂၂-၇-၂၀၂၄ ရက်နေ့တွင်ကျင်းပပြုလုပ်ခဲ့ပါသည်။",
    images: [tfimg, tsimg, ttimg, t4img],
    redirect: "post-3",
  },
  {
    title: "မီးဘေးကြိုတင်ကာကွယ်ရေးပညာပေးဟောပြောပွဲကျင်းပ",
    description:
      "	မုံရွာစီးပွားရေးတက္ကသိုလ်တွင် မီးဘေးကြိုတင်ကာကွယ်ရေးပညာပေးဟောပြောပွဲကို ၁၀-၇-၂၀၂၄ ရက်နေ့တွင် ကျင်းပပြုလုပ်ခဲ့ပါသည်။",
    images: [fourthfimg, fourthsimg, fourthtimg],
    redirect: "post-4",
  },
  {
    title: "အမျိုးသား/အမျိုးသမီးဘောလီဘောအားကစားပြိုင်ပွဲကျင်းပ",
    description:
      "	မုံရွာစီးပွားရေးတက္ကသိုလ်တွင် ဘာသာရပ်အလိုက် အမျိုးသား/ အမျိုးသမီး ဘောလီဘော အားကစားပြိုင်ပွဲ ဗိုလ်လုပွဲနှင့် ဆုချီးမြှင့်ပွဲအခမ်းအနားကို ၂၅-၇-၂၀၂၄ ရက်နေ့တွင် ကျင်းပ ပြုလုပ် ခဲ့ပါသည်။",
    images: [fifthfimg, fifthsimg, fifthtimg, volleyBall],
    redirect: "post-5",
  },
  {
    title: "အမျိုးသားပိုက်ကျော်ခြင်းအားကစားပြိုင်ပွဲကျင်းပ",
    description:
      "	မုံရွာစီးပွားရေးတက္ကသိုလ်တွင် ဘာသာရပ်အလိုက် အမျိုးသားပိုက်ကျော်ခြင်း အားကစားပြိုင်ပွဲ ဗိုလ်လုပွဲနှင့် ဆုချီးမြှင့်ပွဲအခမ်းအနားကို ၂၅-၇-၂၀၂၄ ရက်နေ့တွင် ကျင်းပ ပြုလုပ် ခဲ့ပါသည်။",
    images: [menChinLone, menChinLone1],
    redirect: "post-6",
  },
  {
    title: "မုံရွာစီးပွားရေးတက္ကသိုလ်အတွင်း အပတ်စဉ်စုပေါင်းသန့်ရှင်းရေးပြုလုပ်",
    description:
      "	မုံရွာစီးပွားရေးတက္ကသိုလ် ကျောင်းဝင်းအတွင်းတွင် ပါမောက္ခချုပ် ဒေါက်တာနီလာမြင့်ထူး ဦးဆောင်၍ ဒုတိယပါမောက္ခချုပ်များ၊ ပါမောက္ခ/ဌာနမှူးများ၊ ဆရာ/ဆရာမများနှင့် သင်ကြား၊ စီမံမှ အရာရှိများ ကျောင်းသားများ နှင့်နယ်မြေဝန်ထမ်းများစုပေါင်းကာ ၆-၁၀-၂၀၂၄ ရက်နေ့တွင် ကျောင်းဝင်း အတွင်းစုပေါင်းသန့်ရှင်းရေးပြုလုပ်ခဲ့ကြပါသည်။",
    images: [
      environmentalCleaning,
      environmentalCleaning1,
      environmentalCleaning2,
      environmentalCleaning3,
    ],
    redirect: "post-7",
  },
  {
    title:
      "မုံရွာစီးပွားရေးတက္ကသိုလ် အဝေးသင်သင်တန်းအာစရိယပူဇော်ပွဲအခမ်းအနားကျင်းပ",
    description:
      "	မုံရွာစီးပွားရေးတက္ကသိုလ် ၂၀၂၄ ပညာသင်နှစ် စီးပွားရေးပညာနှင့် စီးပွားစီမံအထူးပြု ကျောင်းသား ကျောင်းသူများ၏ အာစရိယပူဇော်ပွဲ အခမ်းအနားနှင့် နောက်ဆုံးနှစ်ကျောင်းသား ကျောင်းသူများအား နှုတ်ဆက်ပွဲအခမ်းအနားကို ၁၀-၁၁-၂၀၂၄ ရက်နေ့ မွန်းလွဲ (၁၂-၁၁-၂၀၂၄) ရက်နေ့တွင် မုံရွာစီးပွားရေးတက္ကသိုလ် ပင်မဆောင်၊ ငွေရတုခန်းမတွင် ကျင်းပပြုလုပ်ခဲ့ ပါသည်။ အဆိုပါအခမ်းအနားသို့ မုံရွာစီးပွားရေးတက္ကသိုလ်ပါမောက္ခချုပ် ဒေါက်တာနီလာမြင့်ထူး နှင့် ဒုတိယပါမောက္ခချုပ်များ၊ ပါမောက္ခ/ဌာနမှူးများ၊ ဆရာ/ဆရာမများ၊ စီးပွားရေးပညာနှင့် စီးပွားစီမံအထူးပြုကျောင်းသားကျောင်းသူများ တက်ရောက်ခဲ့ကြပါသည်။",
    images: [trWorship, trWorship1],
    redirect: "post-8",
  },
];

const Activities24 = () => {
  const sectionRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sectionId = params.get("section");
    if (sectionId && sectionRef.current) {
      const targetSection = document.getElementById(sectionId);
      if (targetSection) {
        targetSection.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <>
      {" "}
      <div className="pl-32 pr-32 mobile-margin-x-zero">
        <Text
          color="rgba(0, 51, 102, 1)"
          className="pt-8 font-medium mb-2 text-left"
          content="Our Activities and News"
          weight="500"
          size="40px"
        />
        <div
          style={{ backgroundColor: "rgba(255, 215, 0, 1)" }}
          className="h-1 w-11  mb-10 hover:w-32 hover:duration-1000 max-w-[1200px]"></div>
        <Flex direction="col" justify="start" align="start">
          {posts.map((post, index) => (
            <section
              ref={index === 0 ? sectionRef : null}
              key={index}
              id={post.redirect}>
              <ActivitiesPostCard
                id={index}
                title={post.title}
                description={post.description}
                images={post.images}
              />
            </section>
          ))}
        </Flex>
      </div>
    </>
  );
};

export default Activities24;
