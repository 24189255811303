import React, { useState, useEffect } from "react";
import { ClickDropDown, HeadingCard } from "../../cards";
import { Flex, Image, Text } from "../../ui";
import {
  economicsActiFootball,
  economicsActiFootball2,
  economicsActiVolleyball,
  economicsActiWorship,
  economicsActiWorship2,
} from "../../assets";
import EcoLogo from "../../assets/departmentLogo/Economics.jpg";

const EconomicsHeading = () => {
  const [showVisionDetail, setShowVisionDetail] = useState(true);
  const [showMissionDetail, setShowMissionDetail] = useState(false);
  const [showObjectivesDetail, setShowObjectivesDetail] = useState(false);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    economicsActiFootball,
    economicsActiFootball2,
    economicsActiVolleyball,
    economicsActiWorship,
    economicsActiWorship2,
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [images.length]);
  const missions = [
    "To nurture internationally qualified graduates in Economics related areas",
    "To initiate, conduct, participate and support   academic researchers in Economics related areas",
    "To create an academic community that enable to nurture, encourage, empower and open opportunities for the participants in learning environment",
    "To ensure quality and modernized education ",
  ];
  const objectives = [
    "To provide the high-quality academic education, training and research facilities in the field of economics",
    "To produce quality graduates with the analytical expertise that will enable them to function more effectively as economists, policy makers, researchers a",
    "To develop innovative human resources with professional integrity",
  ];
  return (
    <>
      <Flex
        direction="row"
        justify="between"
        align="start"
        className="mt-10 gap-5 mobile-column mobile-center">
        <Flex
          direction="col"
          justify="center"
          align="center"
          className="text-justify mr-5">
          <HeadingCard
            title="Department of Economics"
            departmentLogo={EcoLogo}
          />
          <Text
            content=" The Department of Economics offers one graduate, master, and PhD programme specializing in economics. Those programmes enable students to develop their professional and analytical skills. Firstly, the Department of Economics offers only B. Econ (Economics), which is a full-time course and takes a four-year period. After obtaining a bachelor's degree, the students can apply for the postgraduate course M.Econ (Economics), which is a two-year full-time programme. Our department's highest academic degree is the Doctor of Philosophy in Economics (PhD) which was launched in 2011 at Monywa University of Economics. It is a five-year doctorate programme that includes one year of coursework and four years of dissertation preparation. Graduates are intended to be capable of taking advantage of their work opportunities in their study areas. They are fully prepared to address global challenges, particularly in developing economies. "
            color="rgba(51, 51, 51, 0.8)"
            size="18px"
            weight="400"
            className="mb-1"
          />
          <Text
            content="The Master of Public Administration (MPA) is a three-year graduate degree programme in public policy and administration that started in 2000. The programme comprises a number of courses conducted by senior faculty members of Monywa University of Economics, retired experienced public administrators, and executives from nonprofit organizations. Students taking the MPA programme will develop high-level professional policy skills required by employees in the public and private sectors and by non-government organizations. This programme offers and designs valuable opportunities for persons from a variety of academic and professional backgrounds to develop lifelong education."
            color="rgba(51, 51, 51, 0.8)"
            size="18px"
            weight="400"
            className="mb-1"
          />
        </Flex>
        <div className="flex flex-col max-w-80 align-center justify-start">
          <Image
            src={images[currentImageIndex]}
            width="320px"
            height="250px"
            className="rounded-lg object-cover object-center"
            alt="Commerce Heading"
          />
          <ClickDropDown
            info="Vision"
            detail="To be the center of excellence where qualified professionals with new skills in Economics related areas are trained to meet the challenges of the knowledge age."
            showDetail={showVisionDetail}
            setShowDetail={setShowVisionDetail}
            toggleClick={() => {
              setShowVisionDetail(!showVisionDetail);
              setShowMissionDetail(false);
              setShowObjectivesDetail(false);
            }}
          />
          <ClickDropDown
            info="Mission"
            showDetail={showMissionDetail}
            setShowDetail={setShowMissionDetail}
            detailGroup={missions}
            toggleClick={() => {
              setShowMissionDetail(!showMissionDetail);
              setShowVisionDetail(false);
              setShowObjectivesDetail(false);
            }}
          />
          <ClickDropDown
            className="w-[271px]"
            info="Objectives"
            detailGroup={objectives}
            showDetail={showObjectivesDetail}
            setShowDetail={setShowObjectivesDetail}
            toggleClick={() => {
              setShowObjectivesDetail(!showObjectivesDetail);
              setShowVisionDetail(false);
              setShowMissionDetail(false);
            }}
          />
        </div>
      </Flex>
    </>
  );
};

export default EconomicsHeading;
