import {
  ffimg,
  sfimg,
  tfimg,
  fourthfimg,
  fifthfimg,
  menChinLone,
} from "../../assets";
import { ActivitiesCard } from "../../cards";
import { Text } from "../../ui";

function HeroActivities() {
  const posts = [
    {
      title: "စားသုံးသူကာကွယ်ရေးအသိပညာပေးဟောပြောပွဲကျင်းပ",
      description:
        "မုံရွာစီးပွားရေးတက္ကသိုလ်တွင် စားသုံးသူကာကွယ်ရေး အသိပညာပေးဟောပြောပွဲကို ၂၆-၈-၂၀၂၄ ရက်နေ့တွင် ကျင်းပပြုလုပ်ခဲ့ပါသည်။",
      images: ffimg,
      redirect: "post-1",
    },
    {
      title: "မျောက်ကျောက်ရောဂါအကြောင်းအသိပညာပေးဟောပြောပွဲကျင်းပ",
      description:
        "	မုံရွာစီးပွားရေးတက္ကသိုလ်တွင် မျောက်ကျောက်ရောဂါအကြောင်း အသိပညာပေးဟောပြောပွဲကို ၃-၉-၂၀၂၄ ရက်နေ့တွင် ကျင်းပပြုလုပ်ခဲ့ပါသည်။",
      images: sfimg,
      redirect: "post-2",
    },
    {
      title: "ဥပဒေဆိုင်ရာအသိပညာပေးဟောပြောပွဲကျင်းပ",
      description:
        "	မုံရွာစီးပွားရေးတက္ကသိုလ်တွင် ဥပဒေဆိုင်ရာအသိပညာပေးဟောပြောပွဲကို ၂၂-၇-၂၀၂၄ ရက်နေ့တွင်ကျင်းပပြုလုပ်ခဲ့ပါသည်။",
      images: tfimg,
      redirect: "post-3",
    },
    {
      title: "မီးဘေးကြိုတင်ကာကွယ်ရေးပညာပေးဟောပြောပွဲကျင်းပ",
      description:
        "	မုံရွာစီးပွားရေးတက္ကသိုလ်တွင် မီးဘေးကြိုတင်ကာကွယ်ရေးပညာပေးဟောပြောပွဲကို ၁၀-၇-၂၀၂၄ ရက်နေ့တွင် ကျင်းပပြုလုပ်ခဲ့ပါသည်။",
      images: fourthfimg,
      redirect: "post-4",
    },
    {
      title: "အမျိုးသား/အမျိုးသမီးဘောလီဘောအားကစားပြိုင်ပွဲကျင်းပ",
      description:
        "	မုံရွာစီးပွားရေးတက္ကသိုလ်တွင် ဘာသာရပ်အလိုက် အမျိုးသား/ အမျိုးသမီး ဘောလီဘော အားကစားပြိုင်ပွဲ ဗိုလ်လုပွဲနှင့် ဆုချီးမြှင့်ပွဲအခမ်းအနားကို ၂၅-၇-၂၀၂၄ ရက်နေ့တွင် ကျင်းပ ပြုလုပ် ခဲ့ပါသည်။",
      images: fifthfimg,

      redirect: "post-5",
    },
    {
      title: "အမျိုးသားပိုက်ကျော်ခြင်းအားကစားပြိုင်ပွဲကျင်းပ",
      description:
        "	မုံရွာစီးပွားရေးတက္ကသိုလ်တွင် ဘာသာရပ်အလိုက် အမျိုးသားပိုက်ကျော်ခြင်း အားကစားပြိုင်ပွဲ ဗိုလ်လုပွဲနှင့် ဆုချီးမြှင့်ပွဲအခမ်းအနားကို ၂၅-၇-၂၀၂၄ ရက်နေ့တွင် ကျင်းပ ပြုလုပ် ခဲ့ပါသည်။",
      images: menChinLone,
      redirect: "post-6",
    },
  ];
  return (
    <>
      <Text
        color="rgba(0, 51, 102, 1)"
        content="Our News and Activities"
        size="30px"
        className="text-left mt-10 "
        weight="400"
      />
      <div
        className="h-1 w-11  hover:w-32 hover:duration-1000 mb-5"
        style={{ backgroundColor: "rgba(255, 215, 0, 1)" }}></div>
      <div className="flex flex-row gap-5 flex-wrap justify-start mb-5">
        {posts.map((post, index) => (
          <div key={index}>
            {" "}
            <ActivitiesCard
              title={post.title}
              details={post.description}
              redirect={post.redirect}
              index={index}
              backgroundImage={post.images}
            />
          </div>
        ))}
      </div>
    </>
  );
}

export default HeroActivities;
